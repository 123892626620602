






















import Dictionary, { DICTIONARY_NAME } from '@/common/dictionary';
import ListQuerytor from '@/common/ListQuerytorTs';
import { TableColumn, TableReadyCallbackParams } from '@/components/el-table-plus.vue';
import { ref,defineComponent, onMounted, computed } from '@vue/composition-api';
import ElTablePlus from '../../components/el-table-plus.vue';
export default defineComponent({
    component:{ElTablePlus},
    setup(props, ctx) {
   
      const policyTableColumns:TableColumn[] = [
           { label: "序号", prop: "wfName" },
            { label: "申请地址", type:'actions',align:'center',buttons:[
              { label:'前往申请',type:'text',click:row=> window.open(`${ssoUrl}${row.wfUrl}`)}
            ] },
      ]
      // instCode=工单编码,instSts=工单状态,wfName=项目名称,curNodeName=当前所属环节,reward=申请金额,verify=核定金额,publicityStartDt=公示开始时间,publicityEndDt=公示结束时间,pUniscid=社会信用统一代码
        const progressTableColumns: TableColumn[] = [
            { label: "工单编码", prop: "instCode" },
            { label: "工单状态", prop: "instSts" ,formatter:new Dictionary(DICTIONARY_NAME.INST_STS),filterable:true},
            { label: "项目名称", prop: "wfName" ,filterable:true},
            { label: "政策名称", prop: "wfName" },
            { label: "当前所属环节", prop: "rewcurNodeNameard" },
            { label: "申请金额", prop: "reward" },
            { label: "核定金额", prop: "verify" },
            { label: "公示开始时间", prop: "publicityStartDt" },
            { label: "公示结束时间", prop: "publicityEndDt" },
        ];
        const ssoUrl = "https://esso.zjzwfw.gov.cn/opensso/spsaehandler/metaAlias/sp?spappurl=https://reward.wenzhou.gov.cn/app/ssologin?goto=";
  
        const handlePolicyTableReady = ({querytor}:TableReadyCallbackParams)=>{
          const socialCode  =localStorage.getItem("SOCIAL_CODE");
          console.log(`获取socialCode`,{socialCode})
          querytor.setCondition({socialCode}).query();

        }
        return { policyTableColumns,progressTableColumns, ssoUrl ,handlePolicyTableReady};
    },
    components: { ElTablePlus }
})




